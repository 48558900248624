import { React } from 'react';

const DashboardComponent = () => {

    return (
        <div>
            <div className="card">
                <div className="card-body">
                    <h4 className="card-title">Dashboard</h4>
                    Comming Soon....
                </div>
            </div>
        </div>
    );

}
export default DashboardComponent;