import { React } from 'react';

const ContactUsComponent = () => {

    return (
        <div className='container'>
            <section className="breadcrumbs m-0 p-0" style={{ position: 'relative', padding: '90px 0 90px', backgroundColor: 'white' }}>
                <div className='sec-title'>
                <button type="button" className="btn btn-primary btn-sm">Add</button>
                    <h2>Contact List</h2>
                    <div className='container'>
                    </div>
                    <span className='separator'>
                    </span>
                    <div className='row clearfix'>
                        <div className='col-md-6' style={{width:'100%',marginTop:'-25px'}}>
                            <table className='table table-hover col-6'>
                                <thead> 
                                    <tr className='bg-theme'>
                                        <th>Parameters</th>
                                        <th></th>
                                        <th className='d-flex flex-row-reverse'>Action</th>
                                    </tr>
                                </thead>
                                <tbody className='font'>
                                    <tr>
                                        <td>Tiles/Box</td>
                                        <td></td>
                                        <td>
                                        <button type="button" className="btn btn-danger btn-sm">Delete</button>
                                        <button type="button" className="btn btn-primary btn-sm">Edit</button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Sq.Mts./Box</td>
                                        <td></td>
                                        <td>
                                        <button type="button" className="btn btn-danger btn-sm">Delete</button>
                                        <button type="button" className="btn btn-primary btn-sm">Edit</button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Box/Pallet</td>
                                        <td></td>
                                        <td>
                                        <button type="button" className="btn btn-danger btn-sm">Delete</button>
                                        <button type="button" className="btn btn-primary btn-sm">Edit</button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Pallets/Container(1*20 FCL)</td>
                                        <td></td>
                                        <td>
                                        <button type="button" className="btn btn-danger btn-sm">Delete</button>
                                        <button type="button" className="btn btn-primary btn-sm">Edit</button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Boxes/Container(1*20 FCL)</td>
                                        <td></td>
                                        <td>
                                        <button type="button" className="btn btn-danger btn-sm">Delete</button>
                                        <button type="button" className="btn btn-primary btn-sm">Edit</button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Sq.Mts./Container(1*20 FCL)</td>
                                        <td></td>
                                        <td>
                                        <button type="button" className="btn btn-danger btn-sm">Delete</button>
                                        <button type="button" className="btn btn-primary btn-sm">Edit</button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Thickness of tiles</td>
                                        <td></td>
                                        <td>
                                        <button type="button" className="btn btn-danger btn-sm">Delete</button>
                                        <button type="button" className="btn btn-primary btn-sm">Edit</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );

}
export default ContactUsComponent;