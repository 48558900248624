import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { PAGE_ROUTE, API_URL } from '../shared/constant';
import { Form, Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import LoaderComponent from '../../admin/shared/loader';
import axios from 'axios';
import Crop from '../shared/crop';


const CategoryFormComponent = () => {
    const [imagePreview, setImagePreview] = useState('');
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    let { id } = useParams();
    const [error, setError] = useState([]);
    const inputRef = useRef(null);
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        image: '',
        image_url: '',
        is_tiles: '',
    });
    useEffect(() => {
        id && onEdit();
    }, [id])

    const onSubmit = (e) => {
        e.preventDefault();
        if (!id) {
            if (isInvalid(formData.name)) {
                toast.error("Name Is Required");
                return false;
            }
            if (isInvalid(formData.image)) {
                toast.error("Image Is Required");
                return false;
            }
        }
        setError([]);
        setLoading(true);
        let url = API_URL.ADMIN_CATEGORY_SAVE;
        let body = formData;
        if (id) {
            body['id'] = id;
            url = API_URL.ADMIN_CATEGORY_UPDATE;
        }

        axios.post(url, body).then((response) => {
            setLoading(false);
            if (response.data.status === 1) {
                if (id) {
                    toast.success('Category Updated Successfully', { autoClose: 3000 });
                }

                else {
                    toast.success('Category Created Successfully', { autoClose: 3000 });
                }
                setTimeout(() => {
                    navigate(PAGE_ROUTE.ADMIN_CATEGORY_LIST, { replace: true });
                }, 100);
            } else {
                if (response.data && response.data.data) {
                    let errors = [];
                    Object.keys(response.data.data).forEach((key) => {
                        errors.push(response.data.data[key][0]);
                    });
                    setError(errors);
                    toast.error('Something went wrong..', { autoClose: 3000 })
                }
            }
        }, (error) => {
            setLoading(false);
            toast.error('Something went wrong..', { autoClose: 3000 })
        });
    }

    const isInvalid = (value) => {
        return (!value || value === '' || value == null);
    };

    const onEdit = () => {
        axios.post(API_URL.ADMIN_CATEGORY_GET + id).then((response) => {
            if (response.data.status === 1) {
                setFormData({
                    name: response.data.data.name,
                    description: response.data.data.description,
                    image_url: response.data.data.image,
                    is_tiles: response.data.data.is_tiles
                })
            }
        });
    }



    return (
        <div className="card">
            <div className="card-body">
                <h4 className="card-title">{id ? 'Edit' : 'Add'} Category</h4>
                {error.length > 0 &&
                    <>
                        {[
                            'danger',
                        ].map((variant) => (
                            <Alert key={variant} variant={variant}>
                                {error.map((element, key) => {
                                    return (
                                        <div key={key}>{element}</div>
                                    )
                                })}
                            </Alert>
                        ))}
                    </>
                }
                <form className="forms-sample" onSubmit={onSubmit} >
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className="form-group">
                                <label htmlFor="name">Name</label>
                                {/* <span className='start'>*</span> */}
                                <input
                                    type="text"
                                    name='name'
                                    value={formData.name}
                                    className="form-control"
                                    id="name"
                                    onChange={(event) => {
                                        setFormData({ ...formData, name: event.target.value })
                                    }}
                                />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="form-group">
                                <label htmlFor="Category">Type</label>
                                {/* <span className='start' >*</span> */}
                                <Form.Select value={formData.is_tiles} onChange={(event) => {
                                    setFormData({ ...formData, is_tiles: event.target.value })
                                }}>
                                    <option value="">Select</option>
                                    <option value="1">Tiles</option>
                                    <option value="0">Others</option>
                                </Form.Select>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="Description">Description</label>
                        <Form.Control
                            as="textarea"
                            value={formData.description}
                            style={{ height: '100px' }}
                            onChange={(event) => {
                                setFormData({ ...formData, description: event.target.value })
                            }}
                        />
                    </div>
                    <div className='form-group'>
                        <label>Image</label>
                        <Crop imagePreview={imagePreview} cropShape='round' guid={''}
                            onChangeCropper={async ({ fileCropped, fileCroppedBase64 }) => {
                                setFormData({ ...formData, image: fileCroppedBase64 })
                                setImagePreview(fileCroppedBase64);
                                //   await uploadFile(fileCropped)
                            }} />
                    </div>
                    {id &&
                        <div className='col-md-4'>
                            <label>Preview</label>
                            <div>
                                <a href={formData.image_url} target="_blank" rel="noopener noreferrer">
                                    <img src={formData.image_url} height={100} width={100} alt="category_image" />
                                </a>
                            </div>
                        </div>
                    }
                    <div className='text-end'>
                        {
                            isLoading && <LoaderComponent type={'watch'} height={35} width={35}></LoaderComponent>
                        }
                        <button type="submit" className="btn btn-primary">{id ? 'Update' : 'Create'}</button>
                        <NavLink to={PAGE_ROUTE.ADMIN_CATEGORY_LIST} className="btn btn-secondary">
                            Cancel
                        </NavLink>
                    </div>
                </form>
            </div>
        </div>
    );

}
export default CategoryFormComponent;