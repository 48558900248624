import { React, useEffect, useState } from 'react';
import { PAGE_ROUTE, API_URL } from '../shared/constant';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import TableComponent from '../../admin/shared/table';
import LoaderComponent from '../../admin/shared/loader';

export default function TechnicalSpecificationList() {

    let columns = [{
        field: 'title',
        lable: 'Title'
    }];

    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [isRecall, setIsRecall] = useState(false);

    useEffect(() => {
        getTechnicalSpecification();
    }, []);


    useEffect(() => {
        isRecall && getTechnicalSpecification();
    }, [isRecall]);

    const getTechnicalSpecification = () => {
        setLoading(true);
        axios.post(API_URL.ADMIN_TECHNICAL_SPECIFICATION_LIST, {}).then(res => {
            setData(res.data);
            setIsRecall(false);
            setLoading(false);
        }, error => {
            setLoading(false);
        })
    }

    const onDelete = (item) => {
        let id = item.id;
        axios.post(API_URL.ADMIN_TECHNICAL_SPECIFICATION_DELETE + id).then((response) => {
            setIsRecall(true);
        });
    }

    const onEdit = (item) => {
        let id = item.id;
        navigate(PAGE_ROUTE.ADMIN_TECHNICAL_SPECIFICATION_EDIT + id, { replace: true });
    }

    return (
        <div>
            <div className='card'>
                <div className='card-body'>
                    <h4 className='card-title'>Technical Specification
                        <NavLink to={PAGE_ROUTE.ADMIN_TECHNICAL_SPECIFICATION_CREATE} className="btn btn-primary">
                            Add
                        </NavLink>
                    </h4>
                    {
                        isLoading && <LoaderComponent height={100} width={100}></LoaderComponent>
                    }
                    {
                        !isLoading && <div className="table-responsive">
                            <div className='table-responsive'>
                                <TableComponent
                                    columns={columns}
                                    data={data}
                                    onDelete={onDelete}
                                    onEdit={onEdit}>
                                </TableComponent>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}