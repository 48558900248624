import React from 'react';
import { ThreeDots, Watch } from 'react-loader-spinner'

const LoaderComponent = ({ height, width, type, style, color = '#00BFFF' }) => {

    return (
        <React.Fragment>
            {!type &&
                <div className='row'>
                    <div className='col-md-12' style={{ margin: 'auto', width: '9%' }}>
                        <ThreeDots color={color} height={height} width={width} />
                    </div>
                </div>
            }
            {
                type === 'watch' &&
                <div>
                    <Watch color='black' height={height} width={width} />
                </div>
            }
        </React.Fragment>
    )
}

export default LoaderComponent; 