import React, { useState, useRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
// import './cropper.css';

export const Crop = ({
    cropShape = 'rect',
    onChangeCropper,
    maxSizeMib = 2,
    height = (cropShape === 'round') ? 600 : 600,
    width = (cropShape === 'round') ? 600 : 600,
    guid,
    triggerUpload,
    isButtonHidden = false,
    cropBoxHeight = 10,
    cropBoxWidth = 10,
    imagePreview,
    buttonBackGroundColor = "#ccc"
}) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const inputRef = useRef(null);
    const [image, setImage] = useState("");
    const [cropData, setCropData] = useState("#");
    const [cropper, setCropper] = useState('');
    const fileRef = useRef()

    let buttonCss = {
        backgroundColor: "#4c92e9",
        border: "1px solid #4c92e9",
        color: "white",
        padding: "5px 25px",
        textAlign: "center",
        textDecoration: "none",
        display: "inline-block",
        fontSize: "14px",
        marginTop: 10
    }

    const onChange = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        if (files && files.length && files[0].size < 2000000) {
            const reader = new FileReader();
            reader.onload = () => {
                setImage(reader.result);
                setIsOpen(true)
            };
            reader.readAsDataURL(files[0]);
        } else (
            toast.error('File Must Be Less Than 2 Mb', { autoClose: 3000 })
        )
    };

    const b64toBlob = async (dataURI) => {
        const base64file = await fetch(dataURI)
        const blobFile = await base64file.blob()
        return blobFile
    }

    const getRoundedCanvas = (sourceCanvas) => {
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')
        const width = sourceCanvas.width
        const height = sourceCanvas.height
        canvas.width = width
        canvas.height = height
        context.imageSmoothingEnabled = true
        context.drawImage(sourceCanvas, 0, 0, width, height)
        context.globalCompositeOperation = 'destination-in'
        context.beginPath()
        context.ellipse(width / 2, height / 2, width / 2, height / 2, 0, 0, 2 * Math.PI)
        context.fill()
        return canvas
    }


    const getCropData = async () => {
        if (typeof cropper !== 'undefined') {
            let cropperCanvas = cropper.getCroppedCanvas()
            // if (cropShape === 'round') {
            //     cropperCanvas = getRoundedCanvas(cropperCanvas)
            // }
            setCropData(cropperCanvas.toDataURL())
            const fileCropped = await b64toBlob(cropperCanvas.toDataURL())
            onChangeCropper({
                fileCropped,
                originalFile: fileRef.current,
                fileCroppedBase64: cropperCanvas.toDataURL(),
            })
            setIsOpen(false)
        }
    }

    const triggerInvokedFromParent = () => {
        inputRef?.current?.click();
        console.log('TriggerInvokedFromParent');
    };


    return (
        <div>
            <div style={{ display: "block", width: 200, height: 200, background: buttonBackGroundColor, position: "relative" }} onClick={triggerInvokedFromParent}>
                <input style={{ height: "100%", width: "100%", visibility: "hidden" }} ref={inputRef} type="file" name='fileUploadCx' id="fileUploadCx" onChange={onChange} />
                {(imagePreview) ? <img src={imagePreview} style={{ width: '100%', height: "100%", position: 'absolute', left: 0, top: 0 }} /> : null}
            </div>
            <Modal size="lg" backdrop="static" keyboard={false} show={modalIsOpen} onHide={() => setIsOpen(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Crop</Modal.Title>
                </Modal.Header>
                <Modal.Body className={'crop-' + cropShape}>
                    <Cropper
                        aspectRatio={(cropShape !== 'round') ? 5 / 2 : 1 / 1}
                        src={image}
                        zoomable={false}
                        minCropBoxHeight={cropBoxHeight}
                        minCropBoxWidth={cropBoxWidth}
                        background={false}
                        responsive={true}
                        cropBoxResizable={true}
                        checkOrientation={false}
                        onInitialized={(instance) => {
                            setCropper(instance);
                        }}
                        guides={true}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setIsOpen(false)}>Close</Button>
                    <Button variant="primary" onClick={getCropData}>Crop</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Crop;