/* eslint-disable react-hooks/rules-of-hooks */
import React, { Component, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { BiCategory } from 'react-icons/bi';
import { GiResize, GiBoxUnpacking } from 'react-icons/gi';
import { MdGeneratingTokens } from 'react-icons/md';
import { GrProductHunt, GrCatalog, GrInspect } from 'react-icons/gr';
import { FaSlidersH, FaServer } from 'react-icons/fa';
import { BsNewspaper, BsPersonLinesFill, BsBuilding, BsGlobe, BsFillChatSquareDotsFill, BsBank } from 'react-icons/bs';
import { AiTwotoneSetting, AiFillDashboard } from 'react-icons/ai';
import { GoFileSubmodule } from 'react-icons/go';
import { CgProfile } from 'react-icons/cg';
import { SiMicrosoftteams, SiAudiotechnica } from 'react-icons/si';

const Sidebar = () => {
    const [loginId, setloginId] = useState('')

    const getLoginId = () => {
        let lid = sessionStorage.getItem('login_id');
        setloginId(lid)
    }

    useEffect(() => {
        getLoginId();
    }, [])

    return (
        <>
            <div className="web-category">
                <nav className="sidebar sidebar-offcanvas" id="sidebar">
                <ul className="nav">
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/dashboard">
                                <AiFillDashboard size={20}></AiFillDashboard>
                                <span className="menu-title">Dashboard</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/category">
                                <BiCategory size={20}></BiCategory>
                                <span className="menu-title">Category</span>
                            </NavLink>
                        </li>
                        {(loginId != 3) &&
                            < li className="nav-item">
                                <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/sub-category">
                                    <GoFileSubmodule size={20}></GoFileSubmodule>
                                    <span className="menu-title">Sub Category</span>
                                </NavLink>
                            </li>
                        }
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/size">
                                <GiResize size={20}></GiResize>
                                <span className="menu-title">Size</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/series">
                                <FaServer size={20}></FaServer>
                                <span className="menu-title">Series</span>
                            </NavLink>
                        </li>
                        <li className="nav-item ">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/product">
                                <GrProductHunt size={20}></GrProductHunt>
                                <span className="menu-title">Product</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/about">
                                <BsPersonLinesFill size={20}></BsPersonLinesFill>
                                <span className="menu-title">About</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/inquiry">
                                <CgProfile size={20}></CgProfile>
                                <span className="menu-title">Inquiry</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/slider">
                                <FaSlidersH size={20}></FaSlidersH>
                                <span className="menu-title">Slider</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/specification">
                                <GrInspect size={20}></GrInspect>
                                <span className="menu-title">Product Specs</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/catalogue">
                                <GrCatalog size={20}></GrCatalog>
                                <span className="menu-title">Catalogue</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/news">
                                <BsNewspaper size={20}></BsNewspaper>
                                <span className="menu-title">News</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/setting">
                                <AiTwotoneSetting size={20}></AiTwotoneSetting>
                                <span className="menu-title">Setting</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/infrastructure">
                                <BsBuilding size={20}></BsBuilding>
                                <span className="menu-title">Infrastructure</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/testimonial">
                                <MdGeneratingTokens size={20}></MdGeneratingTokens>
                                <span className="menu-title">Testimonial</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/teams">
                                <SiMicrosoftteams size={20}></SiMicrosoftteams>
                                <span className="menu-title">Teams</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/packing">
                                <GiBoxUnpacking size={20}></GiBoxUnpacking>
                                <span className="menu-title">Packing Details</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/country">
                                <BsGlobe size={20}></BsGlobe>
                                <span className="menu-title">Country Name</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/technical-specification">
                                <SiAudiotechnica size={20}></SiAudiotechnica>
                                <span className="menu-title">Technical Specification</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/quatation">
                                <BsFillChatSquareDotsFill size={20}></BsFillChatSquareDotsFill>
                                <span className="menu-title">Quotation</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/bank">
                                <BsBank size={20}></BsBank>
                                <span className="menu-title">Bank</span>
                            </NavLink>
                        </li>
                    </ul>
                </nav>
            </div>


            <div className="mobile-category">
                <nav className="sidebar sidebar-offcanvas" id="sidebar">
                    <ul className="nav">
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/dashboard">
                                <AiFillDashboard size={20}></AiFillDashboard>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/category">
                                <BiCategory size={20}></BiCategory>
                            </NavLink>
                        </li>
                        {(loginId != 3) &&
                            < li className="nav-item">
                                <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/sub-category">
                                    <GoFileSubmodule size={20}></GoFileSubmodule>

                                </NavLink>
                            </li>
                        }
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/size">
                                <GiResize size={20}></GiResize>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/series">
                                <FaServer size={20}></FaServer>
                            </NavLink>
                        </li>
                        <li className="nav-item ">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/product">
                                <GrProductHunt size={20}></GrProductHunt>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/about">
                                <BsPersonLinesFill size={20}></BsPersonLinesFill>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/inquiry">
                                <CgProfile size={20}></CgProfile>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/slider">
                                <FaSlidersH size={20}></FaSlidersH>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/specification">
                                <GrInspect size={20}></GrInspect>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/catalogue">
                                <GrCatalog size={20}></GrCatalog>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/news">
                                <BsNewspaper size={20}></BsNewspaper>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/setting">
                                <AiTwotoneSetting size={20}></AiTwotoneSetting>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/infrastructure">
                                <BsBuilding size={20}></BsBuilding>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/testimonial">
                                <MdGeneratingTokens size={20}></MdGeneratingTokens>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/teams">
                                <SiMicrosoftteams size={20}></SiMicrosoftteams>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/packing">
                                <GiBoxUnpacking size={20}></GiBoxUnpacking>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/country">
                                <BsGlobe size={20}></BsGlobe>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/technical-specification">
                                <SiAudiotechnica size={20}></SiAudiotechnica>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/quatation">
                                <BsFillChatSquareDotsFill size={20}></BsFillChatSquareDotsFill>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/bank">
                                <BsBank size={20}></BsBank>
                            </NavLink>
                        </li>
                    </ul>
                </nav>
            </div>
        </>
    )
}
export default Sidebar;