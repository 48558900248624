import { React, useState, useEffect, } from 'react';
import RichTextEditor from 'react-rte';
import axios from 'axios';
import { Alert } from 'react-bootstrap';
import { API_URL } from '../../admin/shared/constant';
import { toast } from 'react-toastify';

const AboutUsComponent = () => {


    const [error, setError] = useState([]);
    const [formData, setFormData] = useState({
        about: RichTextEditor.createEmptyValue()
    })


    const onSubmit = (e) => {
        e.preventDefault();
        setError([]);
        let url = API_URL.ADMIN_ABOUT_US_SAVE;
        let body = {
            about: formData.about.toString('html')
        }
        axios.post(url, body).then((response) => {
            if (response.data.status === 1) {
                toast.success('About Us Updated Successfully', { autoClose: 3000 });
            } else {
                if (response.data && response.data.data) {
                    let errors = [];
                    Object.keys(response.data.data).forEach((key) => {
                        errors.push(response.data.data[key][0]);
                    });
                    setError(errors);
                    toast.error('Something went wrong..', { autoClose: 3000 })
                }
            }
        }, (error) => {
            toast.error('Something went wrong..', { autoClose: 3000 })
        });
    }



    const getAbout = () => {
        axios.post(API_URL.ADMIN_SETTING_GET).then((response) => {
            if (response.data.status === 1) {
                setFormData({
                    about: RichTextEditor.createValueFromString(response.data.data.about, 'html')
                })
            }
        });
    }
    const onChange = (v) => {
        setFormData({ ...formData, about: v })
    }

    useEffect(() => {
        getAbout();
    }, []);

    return (
        <div className="card">
            <div className="card-body">
                <h4 className="card-title">About
                    {error.length > 0 &&
                        <>
                            {[
                                'danger',
                            ].map((variant) => (
                                <Alert key={variant} variant={variant}>
                                    {error.map((element, key) => {
                                        return (
                                            <div key={key}>{element}</div>
                                        )
                                    })}
                                </Alert>
                            ))}
                        </>
                    }
                </h4>
                <form className="forms-sample" onSubmit={onSubmit}>
                    <div className="form-group">
                        <div>
                            <RichTextEditor
                                value={formData.about}
                                onChange={onChange}
                                className='text-editor'
                            />
                        </div>
                    </div>
                    <div className='text-end'>
                        <button type="submit" className="btn btn-primary">Update</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AboutUsComponent;