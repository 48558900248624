import React, { useEffect, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { PAGE_ROUTE, API_URL } from '../shared/constant';
import LoaderComponent from '../shared/loader';

const PackingFromComponent = () => {

    let navigate = useNavigate();
    let { id } = useParams();
    const [isLoading, setLoading] = useState(false);
    const [sizeDrp, setsizeDrpData] = useState([]);
    const [error, setError] = useState([]);
    const [title, setTitle] = useState('')
    const [sizeid, setSizeId] = useState('')
    const [parameters, setParameters] = useState([{
        parameter: '',
        value: '',
        title: '',
        sizeid: ''
    }])


    useEffect(() => {
        if (id) {
            onEdit();
        }
        getSizeDrpData()
    }, []);


    const getSizeDrpData = () => {
        axios.post(API_URL.ADMIN_SIZE_LIST, {}).then(res => {
            setsizeDrpData(res.data.data)
        })
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (isInvalid(title)) {
            toast.error("Title Is Required");
            return false;
        }

        setError([]);
        setLoading(true);
        let url = API_URL.ADMIN_PACKING_SAVE;
        let body = {
            id: null,
            title: title,
            parameters: parameters,
            size_id: sizeid,
        };
        if (id) {
            body['id'] = id;
            url = API_URL.ADMIN_PACKING_UPDATE;
        }
        axios.post(url, body).then((response) => {
            setLoading(false);
            if (response.data.status === 1) {
                if (id) {
                    toast.success('Packing Updated Successfully', { autoClose: 3000 });
                } else {
                    toast.success('Packing Created Successfully', { autoClose: 3000 });
                }
                setTimeout(() => {
                    navigate(PAGE_ROUTE.ADMIN_PACKING_LIST, { replace: true });
                }, 100);
            } else {
                if (response.data && response.data.data) {
                    let errors = [];
                    Object.keys(response.data.data).forEach((key) => {
                        errors.push(response.data.data[key][0]);
                    });
                    setError(errors);
                    toast.error('Something went wrong..', { autoClose: 3000 })
                }
            }
        }, (error) => {
            setLoading(false);
            toast.error('Something went wrong..', { autoClose: 3000 })
        });
    }

    const isInvalid = (value) => {
        return (!value || value === '' || value == null);
    };


    const onEdit = () => {
        axios.post(API_URL.ADMIN_PACKING_GET + id).then((response) => {
            if (response.data.status === 1) {
                setTitle(response.data.data.title);
                setParameters(JSON.parse(response.data.data.detail));
                setSizeId(response.data.data.size_id)
            }
        });
    }

    const addParameter = () => {
        let pr = [...parameters]
        pr.push({
            parameter: '',
            value: ''
        })
        setParameters(pr)
    }

    const removeParameter = (i) => {
        let pr = [...parameters]
        pr.splice(i, 1)
        setParameters(pr)
    }

    const updateValue = (i, name, value) => {
        let pr = [...parameters]
        if (pr[i]) {
            pr[i][name] = value;
        }
        setParameters(pr)
    }


    return (
        <div className="card">
            <div className="card-body">
                <h4 className="card-title">{id ? 'Edit' : 'Add'} Packing Details
                    {error.length > 0 &&
                        <>
                            {[
                                'danger',
                            ].map((variant) => (
                                <Alert key={variant} variant={variant}>
                                    {error.map((element, key) => {
                                        return (
                                            <div key={key}>{element}</div>
                                        )
                                    })}
                                </Alert>
                            ))}
                        </>
                    }
                </h4>
                <form className="forms-sample" onSubmit={onSubmit}>
                    <div className='row'>
                        <div className='col-md-5'>
                            <div className='form-group'>
                                <label>Size</label>
                                <Form.Select
                                    aria-label="Series"
                                    value={sizeid}
                                    onChange={(event) => {
                                        setSizeId(event.target.value)
                                    }}>
                                    value={sizeid}
                                    <option value="">Select</option>
                                    {
                                        sizeDrp.map((res, key) => {
                                            return (
                                                <option key={key} value={res.id}>{res.name} ({res.category_name} - {res.sub_category_name})</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            </div>
                        </div>
                        <div className='col-md-5'>
                            <div className="form-group">
                                <label htmlFor="name">Title</label>
                                <input type="text"
                                    title='title'
                                    className="form-control"
                                    value={title}
                                    id="title"
                                    onChange={(event) => {
                                        setTitle(event.target.value)
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    {parameters.map((item, i) => {
                        return <div className='row' key={i}>
                            <div className='col-md-5'>
                                <div className="form-group">
                                    <label htmlFor="name">Parameter</label>
                                    <input type="text"
                                        name='parameter'
                                        className="form-control"
                                        value={item.parameter}
                                        id="parameter"
                                        onChange={(event) => {
                                            updateValue(i, 'parameter', event.target.value)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='col-md-5'>
                                <div className="form-group">
                                    <label htmlFor="name">Value</label>
                                    <input type="text"
                                        name='value'
                                        className="form-control"
                                        value={item.value}
                                        id="name"
                                        onChange={(event) => {
                                            updateValue(i, 'value', event.target.value)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='col-md-2'>
                                <div className="form-group">
                                    <label className='d-block' >&nbsp;</label>
                                    {(i === 0) ?
                                        <button className="btn btn-primary" type='button'
                                            onClick={addParameter}>Add</button> :
                                        <button className="btn btn-danger" type='button'
                                            onClick={() => removeParameter(i)}>Delete</button>
                                    }
                                </div>
                            </div>
                        </div>
                    })}
                    <div className='text-end'>
                        {
                            isLoading && <LoaderComponent type={'watch'} height={35} width={35}></LoaderComponent>
                        }
                        <button type="submit" className="btn btn-primary">{id ? 'Update' : 'Create'}</button>
                        <NavLink to={PAGE_ROUTE.ADMIN_PACKING_LIST} className="btn btn-secondary">
                            Cancel
                        </NavLink>
                    </div>
                </form>
            </div>
        </div>
    );

}
export default PackingFromComponent;