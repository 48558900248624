import { React, useEffect, useState } from 'react';
import { PAGE_ROUTE, API_URL } from '../shared/constant';
import { useNavigate } from 'react-router-dom';
import TableComponent from '../../admin/shared/table';
import moment from 'moment'
import LoaderComponent from '../../admin/shared/loader';
import axios from 'axios';

const QuoatationListComponent = () => {
    const columns = [{
        field: 'name',
        lable: 'Name'
    }, {
        field: 'email',
        lable: 'Email'
    }, {
        field: 'contact_no',
        lable: 'Contact Number'
    }, {
        field: 'country_name',
        lable: 'Country'
    }, {
        field: 'product_name',
        lable: 'Product'
    }, {
        field: 'created_at',
        lable: 'Inquiry At',
        format: 'DD/MM/YYYY'
    }];

    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [isRecall, setIsRecall] = useState(false);

    useEffect(() => {
        getQuatation();
    }, []);

    useEffect(() => {
        isRecall && getQuatation();
    }, [isRecall]);

    const getQuatation = () => {
        setLoading(true);
        axios.post(API_URL.ADMIN_QUATATION_LIST, {}).then(res => {
            res.data.data.map((element) => {
                element.created_at = moment(element.created_at).format('DD/MM/YYYY');
                element.country_name = element.country.name;
                element.product_name = element.product.name;
            });
            setData(res.data.data);
            setIsRecall(false);
            setLoading(false);
        }, error => {
            setLoading(false);
        })
    };

    const onDelete = (item) => {
        let id = item.id;
        axios.post(API_URL.ADMIN_QUATATION_DELETE + id).then((response) => {
            setIsRecall(true);
        });
    };

    const onEdit = (item) => {
        navigate(PAGE_ROUTE.ADMIN_QUATATION_EDIT + item.id, { replace: true });
    };

    return (
        <div>
            <div className='card'>
                <div className='card-body'>
                    <h4 className='card-title'>Quotation</h4>
                    {
                        isLoading && <LoaderComponent height={100} width={100}></LoaderComponent>
                    }
                    {
                        !isLoading && <div className="table-responsive">
                            <div className='table-responsive'>
                                <TableComponent
                                    columns={columns}
                                    data={data}
                                    onDelete={onDelete}
                                    onEdit={onEdit}>
                                </TableComponent>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default QuoatationListComponent;