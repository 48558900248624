import React, { useState, useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoaderComponent from '../../admin/shared/loader';
import { PAGE_ROUTE, API_URL } from '../shared/constant';
import Crop from '../shared/crop';



const TestimonialFormComponent = () => {

    const [isLoading, setLoading] = useState(false);
    const [imagePreview, setImagePreview] = useState('');
    const navigate = useNavigate();
    let { id } = useParams();
    const [error, setError] = useState([]);
    const [formData, setFormData] = useState(
        {
            client_name: '',
            review: '',
            image: '',
            image_url: '',
            client_address: ''
        });

    useEffect(() => {
        id && onEdit();
    }, [id])


    const onSubmit = (e) => {
        e.preventDefault();

        if (isInvalid(formData.client_name)) {
            toast.error("Client Name Is Required");
            return false;
        }

        if (!id && isInvalid(formData.image)) {
            toast.error("Image Is Required");
            return false;
        }
        setError([]);
        setLoading(true);
        let url = API_URL.ADMIN_TESTIMONIAL_SAVE;
        let body = formData;
        if (id) {
            body['id'] = id;
            url = API_URL.ADMIN_TESTIMONIAL_UPDATE;
        }
        axios.post(url, body).then((response) => {
            setLoading(false);
            if (response.data.status === 1) {
                if (id) {
                    toast.success('Testimonial Updated Successfully', { autoClose: 3000 });
                } else {
                    toast.success('Testimonial Created Successfully', { autoClose: 3000 });
                }
                setTimeout(() => {
                    navigate(PAGE_ROUTE.ADMIN_TESTIMONIAL_LIST, { replace: true });
                }, 100);
            } else {
                if (response.data && response.data.data) {
                    let errors = [];
                    Object.keys(response.data.data).forEach((key) => {
                        errors.push(response.data.data[key][0]);
                    });
                    setError(errors);
                    toast.error('Something went wrong..', { autoClose: 3000 })
                }
            }
        }, (error) => {
            setLoading(false);
            toast.error('Something went wrong..', { autoClose: 3000 })
        });
    }

    const isInvalid = (value) => {
        return (!value || value == '' || value == null);
    };

    const onEdit = () => {
        axios.post(API_URL.ADMIN_TESTIMONIAL_GET + id).then((response) => {
            if (response.data.status === 1) {
                setFormData({
                    client_name: response.data.data.client_name,
                    review: response.data.data.review,
                    image_url: response.data.data.image_url,
                    client_address: response.data.data.client_address
                });
            }
        });
    }

    return (
        <div>
            <div className='card'>
                <div className='card-body'>
                    <h4 className='card-title'>{id ? 'Edit' : 'Add'} Testimonial</h4>
                    {error.length > 0 &&
                        <>
                            {[
                                'danger',
                            ].map((variant) => (
                                <Alert key={variant} variant={variant}>
                                    {error.map((element, key) => {
                                        return (
                                            <div key={key}>{element}</div>
                                        )
                                    })}
                                </Alert>
                            ))}
                        </>
                    }
                    <form className="forms-sample" onSubmit={onSubmit} >
                        <div className='row' >
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Client Name</label>
                                    {/* <span className='start'>*</span> */}
                                    <input className='form-control'
                                        name='client_name'
                                        type="text"
                                        value={formData.client_name}
                                        onChange={(event) => {
                                            setFormData({ ...formData, client_name: event.target.value })
                                        }}
                                    ></input>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Address</label>
                                    <input
                                        className='form-control'
                                        name='client_address'
                                        type="text"
                                        value={formData.client_address}
                                        onChange={(event) => {
                                            setFormData({ ...formData, client_address: event.target.value })
                                        }}></input>
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <div className='form-group'>
                                    <label>Review</label>
                                    <input
                                        className='form-control'
                                        name='review'
                                        type="text"
                                        value={formData.review}
                                        onChange={(event) => {
                                            setFormData({ ...formData, review: event.target.value })
                                        }}></input>
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <div className='form-group'>
                                    <label>Image</label>
                                    <Crop imagePreview={imagePreview} cropShape='round' guid={''}
                                        onChangeCropper={async ({ fileCropped, fileCroppedBase64 }) => {
                                            setFormData({ ...formData, image: fileCroppedBase64 })
                                            setImagePreview(fileCroppedBase64);
                                            //   await uploadFile(fileCropped)
                                        }} />
                                </div>
                            </div>
                            {id &&
                                <div className='col-md-12 mb-2'>
                                    <a href={formData.image_url} target="_blank" rel="noopener noreferrer">
                                        <img src={formData.image_url} style={{ width: '100%' }} alt="testimonial_image" />
                                    </a>
                                </div>
                            }
                            <div className='col-md-12 text-right'>
                                <div className='text-end'>
                                    {
                                        isLoading && <LoaderComponent type={'watch'} height={35} width={35}></LoaderComponent>
                                    }
                                    <button type="submit" className="btn btn-primary" onSubmit={onSubmit}>{id ? 'Update' : 'Create'}</button>
                                    <NavLink to={PAGE_ROUTE.ADMIN_TESTIMONIAL_LIST} className="btn btn-secondary">
                                        Cancel
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );

}
export default TestimonialFormComponent;