import { React, useState, useEffect, } from 'react';
import { Form, Alert } from 'react-bootstrap';
import { API_URL } from '../shared/constant';
import { toast } from 'react-toastify';
import TableComponent from '../../admin/shared/table';
import LoaderComponent from '../../admin/shared/loader';
import axios from 'axios';

const SpecificationFormComponent = () => {

    let columns = [{
        field: 'name',
        lable: 'Name'
    }];
    const [isLoading, setLoading] = useState(false);
    const [isLoading1, setLoading1] = useState(false);
    const [id, setId] = useState();
    const [data, setData] = useState([]);
    const [categoryDrp, setCategoryDrpData] = useState([]);
    const [isRecall, setIsRecall] = useState(false);
    const [error, setError] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        category_id: '',
    })

    useEffect(() => {
        getSpecs()
    }, []);

    const onDelete = (item) => {
        let id = item.id;
        axios.post(API_URL.ADMIN_SPEC_DELETE + id).then((response) => {
            setIsRecall(true);
        });
    }

    const onEdit = (item) => {
        let id = item.id
        setId(item.id);
        axios.post(API_URL.ADMIN_SPEC_GET + id, { replace: true }).then((response) => {
            if (response.data.status === 1) {
                setFormData({
                    name: response.data.data.name,
                    category_id: response.data.data.category_id,
                })
            }
        });
    }

    const getSpecs = () => {
        setLoading(true);
        axios.post(API_URL.ADMIN_SPEC_LIST, {}).then(res => {
            setData(res.data);
            setIsRecall(false);
            setLoading(false);
        }, error => {
            setLoading(false);
        })
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (isInvalid(formData.name)) {
            toast.error("Name Is Required");
            return false;
        }
        if (isInvalid(formData.category_id)) {
            toast.error("Category Is Required");
            return false;
        }
        setError([]);
        setLoading1(true);
        let url = API_URL.ADMIN_SPEC_SAVE;
        let body = formData;
        if (id) {
            body['id'] = id;
            url = API_URL.ADMIN_SPEC_UPDATE;
        }
        axios.post(url, formData).then((response) => {
            setLoading1(false);
            if (response.data.status === 1) {
                if (id) {
                    toast.success('Specification Updated Successfully', { autoClose: 3000 });
                } else {
                    toast.success('Specification Created Successfully', { autoClose: 3000 });
                }
                setFormData({
                    name: '',
                    category_id: '',
                });
                getSpecs();
            } else {
                if (response.data && response.data.data) {
                    let errors = [];
                    Object.keys(response.data.data).forEach((key) => {
                        errors.push(response.data.data[key][0]);
                    });
                    setError(errors);
                    toast.error('Something went wrong..', { autoClose: 3000 })
                }
            }
        }, (error) => {
            toast.error('Something went wrong..', { autoClose: 3000 })
            setLoading1(false);
        });
    }

    const isInvalid = (value) => {
        return (!value || value === '' || value === null);
    };

    useEffect(() => {
        getCategoryDrpData();
    }, []);

    const getCategoryDrpData = () => {
        axios.post(API_URL.ADMIN_CATEGORY_LIST, {}).then(res => {
            setCategoryDrpData(res.data.data)
        })
    }

    useEffect(() => {
        isRecall && getSpecs();
    }, [isRecall]);

    return (
        <div className="card">
            <div className="card-body">
                <div className="shadow p-3 mb-5 bg-body rounded">
                    <h4 className='card-title'>Add Specification
                        {error.length > 0 &&
                            <>
                                {[
                                    'danger',
                                ].map((variant) => (
                                    <Alert key={variant} variant={variant}>
                                        {error.map((element, key) => {
                                            return (
                                                <div key={key}>{element}</div>
                                            )
                                        })}
                                    </Alert>
                                ))}
                            </>
                        }
                    </h4>
                    <form className="forms-sample" onSubmit={onSubmit}>
                        <div className="row">
                            <div className='col-md-4'>
                                <div className="form-group">
                                    <label htmlFor="Category">Category</label>
                                    {/* <span className='start'>*</span> */}
                                    <Form.Select aria-label="Category" value={formData.category_id} onChange={(event) => {
                                        setFormData({ ...formData, category_id: event.target.value })
                                    }}>
                                        value={formData.category_id}
                                        <option value="">Select</option>
                                        {
                                            categoryDrp.map((res, key) => {
                                                return (
                                                    <option key={key} value={res.id}>{res.name}</option>
                                                )
                                            })
                                        }
                                    </Form.Select>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className="form-group">
                                    <label htmlFor="name">Name</label>
                                    {/* <span className='start'>*</span> */}
                                    <input type="text"
                                        name='name'
                                        className="form-control"
                                        value={formData.name}
                                        id="name"
                                        placeholder="Name"
                                        onChange={(event) => {
                                            setFormData({ ...formData, name: event.target.value })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='col-md-4'>
                                {
                                    isLoading1 && <LoaderComponent type={'watch'} style={{
                                        display: 'inline-block',
                                        position: 'relative',
                                        top: '26px'
                                    }} height={35} width={35}></LoaderComponent>
                                }
                                <button type="submit" className="btn btn-primary" style={{ float: "none", marginTop: '26px' }}>{id ? 'Update' : 'Create'}</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="shadow p-3 mb-5 bg-body rounded">
                    <h4 className='card-title'>Specification</h4>
                    {
                        isLoading && <LoaderComponent></LoaderComponent>
                    }
                    {
                        !isLoading && <div className="table-responsive">
                            <div className='table-responsive'>
                                <TableComponent
                                    columns={columns}
                                    data={data}
                                    onDelete={onDelete}
                                    onEdit={onEdit}>
                                </TableComponent>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}
export default SpecificationFormComponent;