import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Form, Alert } from 'react-bootstrap';
import { NavLink, useParams } from 'react-router-dom';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from 'moment'
import axios from 'axios';
import { API_URL } from '../shared/constant';

const QuoatationEditComponent = () => {

    let { id } = useParams();

    const [bankId, setBankId] = useState('');

    const printDocument = () => {
        const input = document.getElementById('divToPrint');
        html2canvas(input, { scale: 3})
            .then((canvas) => {
                let wid = 0;
                let hgt = 0;
                let img = canvas.toDataURL("image/png", wid = canvas.width, hgt = canvas.height);
                let hratio = hgt / wid
                var pdf = new jsPDF("p", "pt", "a4");
                let width = pdf.internal.pageSize.width;
                let height = width * hratio;
                pdf.addImage(img, 'JPEG', 10, 10, width - 20, height);
                pdf.save("download.pdf");
            });
    }

    const [contact, setContact] = useState({
        address_one: "",
        address_two: "",
        contact_email: "",
        extra_mobile_number: "",
        mobile_number: ""
    });

    useEffect(() => {
        axios.post(API_URL.SITE_CONTACT_INFO, {}).then(res => {
            if (res.data.data) {
                setContact(res.data.data);
            }
        })
    }, []);

    const [quatation, setQuatation] = useState({
        name: '',
        email: '',
        contact_no: '',
        message: '',
        address: '',
        created_at: "",
        invoiceno: '',
        qty: '',
        price: '',
        total: '',
        tax_number: '',
    });

    useEffect(() => {
        axios.post(API_URL.ADMIN_QUATATION_GET + id, {}).then(res => {
            if (res.data.data) {
                res.data.data.created_at = moment().format('DD MMM YYYY');
                res.data.data['invoiceno'] = moment().format('YYYYMMDDHHMMSS');
                setQuatation(res.data.data);
            }
        })
    }, []);

    const [bankDrp, setbankDrpData] = useState([]);

    const [formData, setFormData] = useState({
        bank_name: '',
        branch_name: '',
        account_name: '',
        account_number: '',
        bank_code: '',
        is_active: 1,
    })

    useEffect(() => {
        getBankDrpData();
    }, []);

    const [banks, setBanks] = useState({
        bank_name: '',
        branch_name: '',
        account_name: '',
        account_number: '',
        bank_code: ''
    });

    const getBankDrpData = () => {
        axios.post(API_URL.ADMIN_BANKS_LIST, {}).then(res => {
            setbankDrpData(res.data.data);
            if (res.data.data && res.data.data.length > 0) {
                setBanks({
                    bank_name: res.data.data[0].bank_name,
                    branch_name: res.data.data[0].branch_name,
                    account_name: res.data.data[0].account_name,
                    account_number: res.data.data[0].account_number,
                    bank_code: res.data.data[0].bank_code
                });
                setBankId(res.data.data[0].id);
            }
        })
    }

    return (
        <React.Fragment>
            <Row>
                <Col sm={4}>
                    <div className="form-group">
                        <Form.Select aria-label="Bank" value={bankId} onChange={(event) => {
                            setBankId(event.target.value);
                            let sel = bankDrp.filter((d) => d.id == event.target.value);
                            if (sel && sel.length > 0) {
                                setBanks({
                                    bank_name: sel[0].bank_name,
                                    branch_name: sel[0].branch_name,
                                    account_name: sel[0].account_name,
                                    account_number: sel[0].account_number,
                                    bank_code: sel[0].bank_code
                                });
                            }
                        }}>
                            {
                                bankDrp.map((res, key) => {
                                    return (
                                        <option key={key} value={res.id}>{res.bank_name}</option>
                                    )
                                })
                            }
                        </Form.Select>
                    </div>
                </Col>
                <Col sm={8} className="text-end">
                    <button type="text" className="btn btn-primary mb-2" onClick={printDocument}>Generate PDF</button>&nbsp;
                    <NavLink className="btn btn-secondary mb-2" to="/admin/quatation">Back</NavLink>
                </Col>
            </Row>
            <div id="divToPrint" style={{ position: 'relative', background: 'white', padding: '18px', border: '1px solid black', textTransform: 'uppercase' }}>
                <div style={{ textAlign: 'center', fontSize: '36px', fontWeight: '700' }}>Greenorahomes</div>
                <h3 style={{ marginTop: '10px', textAlign: 'center', fontSize: '17px', fontWeight: '400', color: 'black' }}>{contact.address_one} {contact.address_two}</h3>
                <hr style={{ opacity: '1' }} />

                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '28px', justifyContent: 'space-between' }}>
                    <h5>Tel: <span style={{ fontSize: '18px', fontWeight: '400', color: 'black' }}>{contact.mobile_number}</span></h5>
                    <h5>Email: <span style={{ fontSize: '18px', fontWeight: '400', color: 'black' }}>{contact.contact_email}</span></h5>
                </div>

                <h3 style={{ textAlign: 'center', marginTop: '28px' }}>Comercial Invoice</h3>
                <hr style={{ opacity: '1' }} />

                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '28px', justifyContent: 'space-between' }}>
                    <h5>Invoice No: <span style={{ fontSize: '18px', fontWeight: '400', color: 'black' }}>{quatation.invoiceno}</span></h5>
                    <h5>Date: <span style={{ fontSize: '18px', fontWeight: '400', color: 'black' }}>{quatation.created_at}</span></h5>
                </div>

                <div style={{ marginTop: '20px' }}>
                    <h5>Sold To: <span style={{ fontSize: '18px', fontWeight: '400', color: 'black' }}>{quatation.name}</span></h5>
                    <h5>TAX No: <span style={{ fontSize: '18px', fontWeight: '400', color: 'black' }}>{quatation.tax_number}</span></h5>
                    <h5>Address: <span style={{ fontSize: '18px', fontWeight: '400', color: 'black' }}>{quatation.address}</span></h5>
                </div>

                <h5 style={{ textAlign: 'center', marginTop: '40px' }}>
                    THE SELLER AGREED TO SELL AND THE BUYER AGREED TO BUY THE FOLLOWINGS ON THE UNDER MENTIONED.
                </h5>
                <hr style={{ opacity: '1' }} />
                <div style={{ padding: '30px' }}>
                    <table style={{ width: "100%", border: '1px solid black', textAlign: 'center' }}>
                        <tr style={{ border: '1px solid black' }}>
                            <th style={{ padding: '10px', border: '1px solid black' }}>ComoDity Description</th>
                            <th style={{ padding: '10px', border: '1px solid black' }}>Quality </th>
                            <th style={{ padding: '10px', border: '1px solid black' }}>Unit Price(USD)</th>
                            <th style={{ padding: '10px', border: '1px solid black' }}>Amount (usd)</th>
                        </tr>
                        <tr style={{ border: '1px solid black' }}>
                            <td style={{ padding: '10px', border: '1px solid black' }}>{quatation?.product?.name}</td>
                            <td style={{ padding: '10px', border: '1px solid black' }}>
                                <input
                                    type="text"
                                    name='qty'
                                    style={{ textAlign: 'center' }}
                                    value={quatation.qty}
                                    className="form-control"
                                    id="qty"
                                    onChange={(event) => {
                                        setQuatation({ ...quatation, qty: event.target.value })
                                    }}
                                />
                            </td>
                            <td style={{ padding: '10px', border: '1px solid black' }}>
                                <input
                                    type="text"
                                    name='price'
                                    style={{ textAlign: 'center' }}
                                    value={quatation.price}
                                    className="form-control"
                                    id="price"
                                    onChange={(event) => {
                                        setQuatation({ ...quatation, price: event.target.value })
                                    }}
                                /></td>
                            <td style={{ padding: '10px', border: '1px solid black' }}>
                                <input
                                    type="text"
                                    name='total'
                                    style={{ textAlign: 'center' }}
                                    value={quatation.total}
                                    className="form-control"
                                    id="total"
                                    onChange={(event) => {
                                        setQuatation({ ...quatation, total: event.target.value })
                                    }}
                                /></td>
                        </tr>
                        <tr style={{ border: '1px solid black' }}>
                            <td style={{ padding: '10px', border: '1px solid black' }}>Total</td>
                            <td style={{ padding: '10px', border: '1px solid black' }}>{quatation.qty}</td>
                            <td style={{ padding: '10px', border: '1px solid black' }}>{quatation.price}</td>
                            <td style={{ padding: '10px', border: '1px solid black' }}>{quatation.total}</td>
                        </tr>
                    </table>
                </div>

                <div style={{ marginTop: '15px', textAlign: 'center' }}>
                    <h5>Sample Are Free For Industrial Test Only. No Comercial Value</h5>
                    <h5>Advance Payment For Courier Charge.</h5>
                </div>

                <div style={{ marginTop: '28px' }}>
                    <i><h5>Bank Account:</h5></i>
                    <h6>Beneficiary Bank: <span style={{ fontSize: '18px', fontWeight: '400', color: 'black' }}>{banks.bank_name}</span></h6>
                    <h6>Beneficiary Name: <span style={{ fontSize: '18px', fontWeight: '400', color: 'black' }}>{banks.account_name}</span></h6>
                    <h6>Account: <span style={{ fontSize: '18px', fontWeight: '400', color: 'black' }}>{banks.account_number}</span></h6>
                    <h6>Swift No: <span style={{ fontSize: '18px', fontWeight: '400', color: 'black' }}>{banks.bank_code}</span></h6>
                    {/* <h6>Beneficiary Address: <span style={{ fontSize: '18px', fontWeight: '400', color: 'black' }}>No 18,Hitnish Technology,Near:Umiya Circle,Galaxy Plazza,Gujrat(India)</span></h6> */}

                </div>
            </div>
        </React.Fragment>
    )
}

export default QuoatationEditComponent;