import { React, useEffect, useState } from 'react';
import { PAGE_ROUTE, API_URL } from '../shared/constant';
import { NavLink, useNavigate } from 'react-router-dom';
import TableComponent from '../../admin/shared/table';
import LoaderComponent from '../../admin/shared/loader';
import axios from 'axios';

export default function BankListComponent() {

    const columns = [{
        field: 'bank_name',
        lable: 'Bank Name'
    },{
        field: 'branch_name',
        lable: 'Branch Name'
    },{
        field: 'account_name',
        lable: 'Account Name'
    },{
        field: 'account_number',
        lable: 'Account Number'
    },{
        field: 'bank_code',
        lable: 'Bank Code'
    }];

    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [isRecall, setIsRecall] = useState(false);

    useEffect(() => {
        getBank();
    }, []);

    useEffect(() => {
        isRecall && getBank();
    }, [isRecall]);

    const getBank = () => {
        setLoading(true);
        axios.post(API_URL.ADMIN_BANKS_LIST, {}).then(res => {
            setData(res.data.data);
            setIsRecall(false);
            setLoading(false);
        }, error => {
            setLoading(false);
        })
    };

    const onDelete = (item) => {
        let id = item.id;
        axios.post(API_URL.ADMIN_BANKS_DELETE + id).then((response) => {
            setIsRecall(true);
        });
    };

    const onEdit = (item) => {
        navigate(PAGE_ROUTE.ADMIN_BANKS_EDIT + item.id, { replace: true });
    };

    return (
        <div>
            <div className='card'>
                <div className='card-body'>
                    <h4 className='card-title'>BANKS
                        <NavLink to={PAGE_ROUTE.ADMIN_BANKS_CREATE} className="btn btn-primary">
                            Add
                        </NavLink>
                    </h4>

                    {
                        isLoading && <LoaderComponent height={100} width={100}></LoaderComponent>
                    }
                    {
                        !isLoading && <div className="table-responsive">
                            <div className='table-responsive'>
                                <TableComponent
                                    columns={columns}
                                    data={data}
                                    onDelete={onDelete}
                                    onEdit={onEdit}>
                                </TableComponent>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}