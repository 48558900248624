import React, { useEffect, useState,useRef } from 'react';
import { Form, Alert } from 'react-bootstrap';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { PAGE_ROUTE, API_URL } from '../shared/constant';
import axios from 'axios';
import LoaderComponent from '../../admin/shared/loader';
import { toast } from 'react-toastify';


const InfrastructureFormComponent = () => {

    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [error, setError] = useState([]);
    let { id } = useParams();
    const inputRef = useRef(null);
    const [categoryDrp, setCategoryDrpData] = useState([]);
    const [formData, setFormData] = useState({
        title: '',
        images: '',
    })

    const getCategoryDrpData = () => {
        axios.post(API_URL.SITE_INFRASTRUCTURE_CATEGORY, {}).then(res => {
            setCategoryDrpData(res.data.data)
        })
    }
    useEffect(() => {
        getCategoryDrpData();
    }, []);


    const onSubmit = (e) => {
        e.preventDefault();
        if (isInvalid(formData.title)) {
            toast.error("Title Is Required");
            return false;
        }
        if (isInvalid(formData.images)) {
            toast.error("Image Is Required");
            return false;
        }
        setError([]);
        setLoading(true);
        let url = API_URL.ADMIN_INFRASTRUCTURE_SAVE;
        let body = formData;
        if (id) {
            body['id'] = id;
        }
        console.log(body.images);
        let bodyFormData = new FormData();
        for (let i = 0; i < body.images.length; i++) {
            bodyFormData.append("images[]", body.images[i]);
        }

        bodyFormData.append('title', body.title);
        axios.post(url, bodyFormData,
            { headers: { "Content-Type": "multipart/form-data" } })
            .then((response) => {
                setLoading(false);
                if (response.data.status === 1) {
                    if (id) {
                        toast.success('Infrastructure Updated Successfully', { autoClose: 3000 });
                    }

                    else {
                        toast.success('Infrastructure Created Successfully', { autoClose: 3000 });
                    }
                    setTimeout(() => {
                        navigate(PAGE_ROUTE.ADMIN_INFRASTRUCTURE_LIST, { replace: true });
                    }, 100);
                } else {
                    if (response.data && response.data.data) {
                        let errors = [];
                        Object.keys(response.data.data).forEach((key) => {
                            errors.push(response.data.data[key][0]);
                        });
                        setError(errors);
                        toast.error('Something went wrong..', { autoClose: 3000 })
                    }
                }
            }, (error) => {
                setLoading(false);
                toast.error('Something went wrong..', { autoClose: 3000 })
            });
    }

    const isInvalid = (value) => {
        return (!value || value === '' || value == null);
    };

    return (
        <div className="card">
            <div className="card-body">
                <h4 className="card-title">Add Infrastructure</h4>
                {error.length > 0 &&
                    <>
                        {[
                            'danger',
                        ].map((variant) => (
                            <Alert key={variant} variant={variant}>
                                {error.map((element, key) => {
                                    return (
                                        <div key={key}>{element}</div>
                                    )
                                })}
                            </Alert>
                        ))}
                    </>
                }
                <form className="forms-sample" onSubmit={onSubmit}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="name">Title</label>
                                {/* <span className='start'>*</span> */}
                                <Form.Select
                                    aria-label="Category"
                                    value={formData.title}
                                    onChange={(event) => {
                                        setFormData({ ...formData, title: event.target.value })
                                    }}>
                                    value={formData.title}
                                    <option value="">Select</option>
                                    {
                                        categoryDrp.map((res, key) => {
                                            return (
                                                <option key={key} value={res.id}>{res.name}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label>Image</label>
                                {/* <span className='start'>*</span> */}
                                <input
                                    className='form-control'
                                    accept='image/*'
                                    name='images'
                                    ref={inputRef}
                                    type="file"
                                    multiple
                                    onChange={(e) => {
                                        if (e.target.files && e.target.files[0]) {
                                            if (e.target.files[0].size > 2000000){
                                                toast.error("File size must be less than 2MB");
                                                inputRef.current.value = null;
                                            }else{
                                                setFormData({ ...formData, images: e.target.files });
                                            }
                                        }
                                    }} />
                            </div>
                        </div>
                    </div>
                    <div className='text-end'>
                        {
                            isLoading && <LoaderComponent type={'watch'} height={35} width={35}></LoaderComponent>
                        }
                        <button type="submit" className="btn btn-primary">{id ? 'Update' : 'Create'}</button>
                        <NavLink to={PAGE_ROUTE.ADMIN_INFRASTRUCTURE_LIST} className="btn btn-secondary">
                            Cancel
                        </NavLink>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default InfrastructureFormComponent;