import React, { useState, useEffect, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { PAGE_ROUTE, API_URL } from '../shared/constant';
import { Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FaCloudDownloadAlt } from 'react-icons/fa';
import LoaderComponent from '../../admin/shared/loader';
import axios from 'axios';
import Crop from '../shared/crop';

const CatalogueFormComponent = () => {

    const navigate = useNavigate();
    let { id } = useParams();
    const [imagePreview, setImagePreview] = useState('');
    const [isLoading, setLoading] = useState(false);
    const inputRef = useRef(null);
    const [error, setError] = useState([]);
    const [categoryDrp, setCategoryDrpData] = useState([]);
    const [subCategoryDrp, setSubCategoryDrp] = useState([]);
    const [seriesDrp, setseriesDrpData] = useState([]);
    const [sizeDrp, setsizeDrpData] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        category_id: '',
        sub_category_id: '',
        image: '',
        image_url: '',
        size_id: '',
        series_id: '',
        fileName: ''
    });

    const [file, setFile] = useState();


    const getCategoryDrpData = () => {
        axios.post(API_URL.ADMIN_CATEGORY_LIST, {}).then(res => {
            setCategoryDrpData(res.data.data)
        })
    }

    const getSubCategoryDrpData = (value) => {
        axios.post(API_URL.ADMIN_SUB_CATEGORY_BY_CATEGORY, { category_id: value }).then(res => {
            setSubCategoryDrp(res.data.data)
        })
    }

    const getseriesDrpData = (category_id = null, sub_category_id = null) => {
        let URL = API_URL.ADMIN_SERIES_LIST;
        if (category_id) {
            URL = URL + '?category_id=' + category_id;
        }
        if (sub_category_id) {
            URL = API_URL.ADMIN_SERIES_LIST;
            URL = URL + '?sub_category_id=' + sub_category_id;
        }
        axios.post(URL, {}).then(res => {
            setseriesDrpData(res.data.data)
        })
    };

    const getsizeDrpData = (category_id = null, sub_category_id = null) => {
        let URL = API_URL.ADMIN_SIZE_LIST;
        if (category_id) {
            URL = URL + '?category_id=' + category_id;
        }
        if (sub_category_id) {
            URL = API_URL.ADMIN_SIZE_LIST;
            URL = URL + '?sub_category_id=' + sub_category_id;
        }
        axios.post(URL, {}).then(res => {
            setsizeDrpData(res.data.data)
        })
    };

    useEffect(() => {
        if (id) {
            onEdit();
        } else {
            getCategoryDrpData();
            getSubCategoryDrpData();
        }
    }, [id]);

    const onSubmit = (e) => {
        e.preventDefault();
        if (!id) {
            if (isInvalid(formData.name)) {
                toast.error("Name Is Required");
                return false;
            }
            if (isInvalid(formData.category_id)) {
                toast.error("Category-Name Is Required");
                return false;
            }
            if (isInvalid(formData.fileName)) {
                toast.error("Pdf Is Required");
                return false;
            }
            if (isInvalid(formData.image)) {
                toast.error("Image Is Required");
                return false;
            }
        }
        setError([]);
        setLoading(true);
        let url = API_URL.ADMIN_PDF_SAVE;
        let body = formData;
        if (id) {
            body['id'] = id;
            url = API_URL.ADMIN_PDF_UPDATE;
        }
        let bodyFormData = new FormData();
        id && bodyFormData.append('id', id);
        !id && bodyFormData.append('file', file);
        bodyFormData.append('image', body.image);
        bodyFormData.append('name', body.name);
        bodyFormData.append('category_id', body.category_id);
        bodyFormData.append('sub_category_id', body.sub_category_id);
        bodyFormData.append('size_id', body.size_id);
        bodyFormData.append('series_id', body.series_id);
        axios.post(
            url,
            bodyFormData,
            { headers: { "Content-Type": "multipart/form-data" } }
        ).then((response) => {
            setLoading(false);
            if (response.data.status === 1) {
                if (id) {
                    toast.success('Catalogue Updated Successfully', { autoClose: 3000 });
                } else {
                    toast.success('Catalogue Created Successfully', { autoClose: 3000 });
                }
                setTimeout(() => {
                    navigate(PAGE_ROUTE.ADMIN_CATALOGUE_LIST, { replace: true });
                }, 100);
            } else {
                if (response.data && response.data.data) {
                    let errors = [];
                    Object.keys(response.data.data).forEach((key) => {
                        errors.push(response.data.data[key][0]);
                    });
                    setError(errors);
                    toast.error('Something went wrong..', { autoClose: 3000 })
                }
            }
        }, (error) => {
            toast.error('Something went wrong..', { autoClose: 3000 })
            setLoading(false);
        });
    }

    const isInvalid = (value) => {
        return (!value || value === '' || value === null);
    };

    const onEdit = () => {
        axios.post(API_URL.ADMIN_PDF_GET + id).then((response) => {
            if (response.data.status === 1) {
                getCategoryDrpData();
                getSubCategoryDrpData(response.data.data.category_id);
                getseriesDrpData(response.data.data.category_id, response.data.data.sub_category_id);
                getsizeDrpData(response.data.data.category_id, response.data.data.sub_category_id);
                setFormData({
                    name: response.data.data.name,
                    category_id: response.data.data.category_id,
                    sub_category_id: response.data.data.sub_category_id,
                    size_id: response.data.data.size_id,
                    series_id: response.data.data.series_id,
                    fileName: response.data.data.pdf_url,
                    image_url: response.data.data.image_url,
                });
            }
        });
    }



    return (
        <div className="card">
            <div className="card-body">
                <h4 className="card-title">{id ? 'Edit' : 'Add'} Catalogue</h4>
                {error.length > 0 &&
                    <>
                        {[
                            'danger',
                        ].map((variant) => (
                            <Alert key={variant} variant={variant}>
                                {error.map((element, key) => {
                                    return (
                                        <div key={key}>{element}</div>
                                    )
                                })}
                            </Alert>
                        ))}
                    </>
                }
                <form className="forms-sample" onSubmit={onSubmit}>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='form-group'>
                                <label>Name</label>
                                {/* <span className='start'>*</span> */}
                                <input
                                    type="text"
                                    name='name'
                                    value={formData.name}
                                    className="form-control"
                                    id="name"
                                    onChange={(event) => {
                                        setFormData({ ...formData, name: event.target.value })
                                    }}
                                />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='form-group'>
                                <label>Category</label>
                                {/* <span className='start'>*</span> */}
                                <Form.Select aria-label="Category" value={formData.category_id} onChange={(event) => {
                                    getSubCategoryDrpData(event.target.value);
                                    getseriesDrpData(event.target.value);
                                    getsizeDrpData(event.target.value);
                                    setFormData({ ...formData, category_id: event.target.value })
                                }}>
                                    value={formData.category_id}
                                    <option value="">Select</option>
                                    {
                                        categoryDrp.map((res, key) => {
                                            return (
                                                <option key={key} value={res.id}>{res.name}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="form-group">
                                <label htmlFor="Sub Category">Sub Category</label>
                                <Form.Select aria-label="Sub Category" value={formData.sub_category_id} onChange={(event) => {
                                    getseriesDrpData(formData.category_id, event.target.value);
                                    getsizeDrpData(formData.category_id, event.target.value);
                                    setFormData({ ...formData, sub_category_id: event.target.value })
                                }}>
                                    value={formData.sub_category_id}
                                    <option value="">Select</option>
                                    {
                                        subCategoryDrp.map((res, key) => {
                                            return (
                                                <option key={key} value={res.id}>{res.name}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='form-group'>
                                <label>Size</label>
                                <Form.Select aria-label="Series" value={formData.size_id} onChange={(event) => {
                                    setFormData({ ...formData, size_id: event.target.value })
                                }}>
                                    value={formData.size_id}
                                    <option value="">Select</option>
                                    {
                                        sizeDrp.map((res, key) => {
                                            return (
                                                <option key={key} value={res.id}>{res.name}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='form-group'>
                                <label>Series</label>
                                <Form.Select aria-label="Series" value={formData.series_id} onChange={(event) => {
                                    setFormData({ ...formData, series_id: event.target.value })
                                }}>
                                    value={formData.series_id}
                                    <option value="">Select</option>
                                    {
                                        seriesDrp.map((res, key) => {
                                            return (
                                                <option key={key} value={res.id}>{res.name}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            </div>
                        </div>
                        <div className='col-md-4 position-relative'>
                            <div className='form-group'>
                                <label>PDF</label>
                                {/* <span className='start'>*</span> */}
                                <input className='form-control'
                                    accept='application/pdf'
                                    disabled={id}
                                    name='file'
                                    type="file"
                                    id="file"
                                    onChange={(e) => {
                                        if (e.target.files && e.target.files[0]) {
                                            setFile(e.target.files[0]);
                                            setFormData({ ...formData, fileName: e.target.files[0].name })
                                        }
                                    }}
                                />
                                {id && <a className='pdf-view-icon' href={formData.fileName} target="_blank" rel="noopener noreferrer"><FaCloudDownloadAlt size={20}></FaCloudDownloadAlt>&nbsp;View</a>}
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='form-group'>
                                <label>Image</label>
                                <Crop imagePreview={imagePreview} cropShape='round' guid={''}
                                    onChangeCropper={async ({ fileCropped, fileCroppedBase64 }) => {
                                        setFormData({ ...formData, image: fileCroppedBase64 })
                                        setImagePreview(fileCroppedBase64);
                                        //   await uploadFile(fileCropped)
                                    }} />
                            </div>
                        </div>
                        {id &&
                            <div className='col-md-4'>
                                <label>Preview</label>
                                <div>
                                    <a href={formData.image_url} target="_blank" rel="noopener noreferrer">
                                        <img src={formData.image_url} height={100} width={100} alt="product_image" />
                                    </a>
                                </div>
                            </div>
                        }
                    </div>
                    <div className='text-end'>
                        {
                            isLoading && <LoaderComponent type={'watch'} height={35} width={35}></LoaderComponent>
                        }
                        <button type="submit" className="btn btn-primary" >{id ? 'Update' : 'Create'}</button>
                        <NavLink to="/admin/catalogue" className="btn btn-secondary">
                            Cancel
                        </NavLink>
                    </div>
                </form>
            </div>
        </div>
    );

}

export default CatalogueFormComponent;