import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { PAGE_ROUTE, API_URL } from '../shared/constant';
import { Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import LoaderComponent from '../../admin/shared/loader';
import axios from 'axios';

const BankFormComponent = () => {


    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    let { id } = useParams();
    const [error, setError] = useState([]);
    const [formData, setFormData] = useState({
        bank_name: '',
        branch_name: '',
        account_name: '',
        account_number: '',
        bank_code: '',
        is_active: 1,
    });
    useEffect(() => {
        id && onEdit();
    }, [id])

    const onSubmit = (e) => {
        e.preventDefault();
        if (isInvalid(formData.bank_name)) {
            toast.error("Bank Name Is Required");
            return false;
        }
        setError([]);
        setLoading(true);
        let url = API_URL.ADMIN_BANKS_SAVE;
        let body = formData;
        if (id) {
            body['id'] = id;
            // url = API_URL.ADMIN_BANKS_UPDATE;
        }
        axios.post(url, body).then((response) => {
            setLoading(false);
            if (response.data.status === 1) {
                if (id) {
                    toast.success('Banks Updated Successfully', { autoClose: 3000 });
                }

                else {
                    toast.success('Banks Created Successfully', { autoClose: 3000 });
                }
                setTimeout(() => {
                    navigate(PAGE_ROUTE.ADMIN_BANKS_LIST, { replace: true });
                }, 100);
            } else {
                if (response.data && response.data.data) {
                    let errors = [];
                    Object.keys(response.data.data).forEach((key) => {
                        errors.push(response.data.data[key][0]);
                    });
                    setError(errors);
                    toast.error('Something went wrong..', { autoClose: 3000 })
                }
            }
        }, (error) => {
            setLoading(false);
            toast.error('Something went wrong..', { autoClose: 3000 })
        });
    }

    const isInvalid = (value) => {
        return (!value || value === '' || value == null);
    };

    const onEdit = () => {
        axios.post(API_URL.ADMIN_BANKS_GET + id).then((response) => {
            if (response.data.status === 1) {
                setFormData({
                    bank_name: response.data.data.bank_name,
                    branch_name: response.data.data.branch_name,
                    account_name: response.data.data.account_name,
                    account_number: response.data.data.account_number,
                    bank_code: response.data.data.bank_code,
                    is_active: 1
                })
            }
        });
    }


    return (
        <div className="card">
            <div className="card-body">
                <h4 className="card-title">{id ? 'Edit' : 'Add'} Banks</h4>
                {error.length > 0 &&
                    <>
                        {[
                            'danger',
                        ].map((variant) => (
                            <Alert key={variant} variant={variant}>
                                {error.map((element, key) => {
                                    return (
                                        <div key={key}>{element}</div>
                                    )
                                })}
                            </Alert>
                        ))}
                    </>
                }
                <form className="forms-sample" onSubmit={onSubmit} >
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className="form-group">
                                <label htmlFor="name">Bank Name</label>
                                <input
                                    type="text"
                                    name='name'
                                    value={formData.bank_name}
                                    className="form-control"
                                    id="name"
                                    onChange={(event) => {
                                        setFormData({ ...formData, bank_name: event.target.value })
                                    }}
                                />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="form-group">
                                <label htmlFor="name">Branch Name</label>
                                <input
                                    type="text"
                                    name='name'
                                    value={formData.branch_name}
                                    className="form-control"
                                    id="name"
                                    onChange={(event) => {
                                        setFormData({ ...formData, branch_name: event.target.value })
                                    }}
                                />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="form-group">
                                <label htmlFor="name">Account Name</label>
                                <input
                                    type="text"
                                    name='name'
                                    value={formData.account_name}
                                    className="form-control"
                                    id="name"
                                    onChange={(event) => {
                                        setFormData({ ...formData, account_name: event.target.value })
                                    }}
                                />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="form-group">
                                <label htmlFor="name">Account Number</label>
                                <input
                                    type="text"
                                    name='name'
                                    value={formData.account_number}
                                    className="form-control"
                                    id="name"
                                    onChange={(event) => {
                                        setFormData({ ...formData, account_number: event.target.value })
                                    }}
                                />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="form-group">
                                <label htmlFor="name">Bank Code</label>
                                <input
                                    type="text"
                                    name='name'
                                    value={formData.bank_code}
                                    className="form-control"
                                    id="name"
                                    onChange={(event) => {
                                        setFormData({ ...formData, bank_code: event.target.value })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='text-end'>
                        {
                            isLoading && <LoaderComponent type={'watch'} height={35} width={35}></LoaderComponent>
                        }
                        <button type="submit" className="btn btn-primary">{id ? 'Update' : 'Create'}</button>
                        <NavLink to={PAGE_ROUTE.ADMIN_BANKS_LIST} className="btn btn-secondary">
                            Cancel
                        </NavLink>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default BankFormComponent;