import { React, useEffect, useState } from 'react';
import { API_URL } from '../shared/constant';
import TableComponent from '../../admin/shared/table';
import axios from 'axios';
import moment from 'moment'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function InquiryListComponent() {

    const columns = [{
        field: 'name',
        lable: 'Name'
    }, {
        field: 'email',
        lable: 'Email'
    }, {
        field: 'contact_no',
        lable: 'Contact Number'
    }, {
        field: 'created_at',
        lable: 'Inquiry At',
        format: 'DD/MM/YYYY'
    }];

    const [data, setData] = useState([]);
    const [isRecall, setIsRecall] = useState(false);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [message, setMessage] = useState('')

    useEffect(() => {
        getInquiry();
    }, []);

    const onEdit = (item) => {
        setMessage(item.message)
        setShow(true)
    }


    useEffect(() => {
        isRecall && getInquiry();
    }, [isRecall]);

    const getInquiry = () => {
        axios.post(API_URL.ADMIN_SETTING_INQUIRIES, {}).then(res => {
            res.data.data.map((element) => {
                element.created_at = moment(element.created_at).format('DD/MM/YYYY');
            });
            setData(res.data.data);
            setIsRecall(false);
        })
    };

    const onDelete = (item) => {
        let id = item.id;
        axios.post(API_URL.ADMIN_SETTING_INQUIRIES_DELETE , { id: id }).then((response) => {
            setIsRecall(true);
        });
    };


    return (
        <div>
            <div className='card'>
                <div className='card-body'>
                    <h4 className='card-title'>Inquiry
                    </h4>
                    <div className='table-responsive'>
                        <TableComponent
                            columns={columns}
                            data={data}
                            onDelete={onDelete}
                            isShowAction={true}
                            editBtnLabel={'View'}
                            onEdit={onEdit}
                            onHide={handleClose}>
                        </TableComponent>

                        <Modal show={show} onHide={handleClose}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                Message
                            </Modal.Header>
                            <Modal.Body>{message}</Modal.Body>
                        </Modal>

                    </div>
                </div>
            </div>
        </div>
    )
}